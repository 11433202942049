import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetDeliveryTicketQuery,
  useShareDeliveryTicketMutation,
  useUpdateDeliveryTicketMutation,
} from 'redux/dme/dme.api';
import { useAppSettings } from 'context';
import { useContactId } from '../hooks/useContactId';
import { useNavigationHelper } from 'hooks/useNavigationHelper';

import DeliveryTicketForm from '../presentation/DeliveryTicket/DeliveryTicketForm';
import {
  initialQuestionnaireData,
  initialProofOfDeliveryData,
} from '../constants/initialDeliveryTicketValues';
import { ConfirmModal } from 'components/common/ConfirmModal';
import FullPageLoader from 'components/common/FullPageLoader';
import DmeHeader from '../presentation/common/DmeHeader/DmeHeader';
import { adaptOrdersForRequestBody } from '../adapters/orders.adapter';
import { validateDeliveryTicket } from '../validation/validateDeliveryTicket';
import ActionButtons from '../presentation/common/ActionButtons/ActionButtons';
import { NoticeMessage } from '../presentation/common/NoticeMessage/NoticeMessage';
import {
  initialSettingValues,
  initialTestingValues,
} from '../presentation/DeliveryTicket/ProductSettingAndTestingForm/ProductSettingAndTestingForm';
import { initialDocumentValues } from '../presentation/DeliveryTicket/DocumentCheckList/DocumentCheckList';
import { initialHomeAssessmentValues } from '../presentation/DeliveryTicket/HomeAssessmentAndSafety/HomeAssessmentAndSafety';

import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { animateToFormError } from 'util/scroll';
import { getErrorMessage } from 'util/errorMessageHandler';
import { removeAllEmptyFieldValuesForObject } from 'util/objectUtils';
import { downloadFile, generatePdfBlob } from 'util/printPdf';
import DeliveryTicketPdfView from './DeliveryTicketPdfView';

const LABEL_PREFIX = 'dme';

const DeliveryTicketPage = ({ isEdit }) => {
  const params = useParams();
  const productOrderId = params?.productOrderId;
  const deliveryTicketId = params?.deliveryTicketId;

  const { isStaff, contactId } = useContactId();

  const {
    data: deliveryTicketResponse,
    isError,
    error,
    isLoading: isGetDeliveryTicketLoading,
  } = useGetDeliveryTicketQuery({ productOrderId, deliveryTicketId });

  const {
    organizationContactPhone,
    organizationContactEmail,
    logoUrl,
    organizationName,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
  } = useAppSettings();

  const [updateDeliveryTicket, { isLoading: isUpdateTicketLoading }] =
    useUpdateDeliveryTicketMutation();
  const [shareDeliveryTicketPdf, { isLoading: isSharePdfLoading }] =
    useShareDeliveryTicketMutation(productOrderId);

  const [resendPdfModal, setResendPdfModal] = useState(false);
  const [isAlreadyShared, setIsAlreadyShared] = useState(false);

  const [deliveryTicketData, setDeliveryTicketData] = useState({
    homeAssessmentData: initialHomeAssessmentValues,
    questionnaireData: initialQuestionnaireData,
    documentsData: initialDocumentValues,
    settingAndTestingData: {
      settings: initialSettingValues,
      tests: initialTestingValues,
    },
    consent: {
      signedDate: '',
      signature: '',
    },
    setupSignatureData: '',
    proofOfDeliveryData: initialProofOfDeliveryData,
    deliveryInfo: {},
    orderItemData: [],
  });
  const [deliveryPacketGenerated, setDeliveryPacketGenerated] = useState(false);
  const [formErrors, setFormErrors] = useState();

  const nav = useNavigationHelper();

  useEffect(() => {
    if (!deliveryTicketResponse) return;

    setIsAlreadyShared(
      deliveryTicketResponse.orderItemData?.every(
        (item) => item?.deliveryTicketSharedAt,
      ),
    );
    setDeliveryPacketGenerated(
      deliveryTicketResponse.orderItemData?.every(
        (item) => item?.deliveryPacketGenerated,
      ),
    );

    setDeliveryTicketData((prev) => ({
      ...prev,
      ...removeAllEmptyFieldValuesForObject(deliveryTicketResponse),
    }));
  }, [deliveryTicketResponse]);

  useEffect(() => {
    if (!formErrors?.hasError) return;

    const errors = validateDeliveryTicket(deliveryTicketData);
    setFormErrors(errors);
  }, [deliveryTicketData, formErrors?.hasError]);

  const redirectToPrint = async () => {
    try {
      const blob = await generatePdfBlob(
        <DeliveryTicketPdfView
          productOrderId={productOrderId}
          deliveryTicketId={deliveryTicketId}
          deliveryTicketResponse={deliveryTicketData}
          logoUrl={logoUrl}
          organizationName={organizationName}
          organizationContactPhone={organizationContactPhone}
          billingAddress={billingAddress}
          billingCity={billingCity}
          billingState={billingState}
          billingZipCode={billingZipCode}
        />,
      );
      downloadFile(blob, 'Delivery-ticket.pdf');
    } catch (error) {
      toast.error({
        title: '',
        message: `Error downloading Delivery-ticket.pdf: ${error.message}`,
      });
    }
  };

  // Set the parent state from its child component in sections for nested object
  const setDeliveryTicketHandler = (data, section) => {
    if (!section) {
      setDeliveryTicketData((prev) => ({ ...prev, ...data }));
    }
    setDeliveryTicketData((prev) => ({
      ...prev,
      [section]: data,
    }));
  };

  const handleSave = async () => {
    const { orderItemData, ...deliveryTicket } = deliveryTicketData;
    const payload = {
      orderItems: adaptOrdersForRequestBody(orderItemData),
      deliveryTicket,
    };

    if (!isStaff) {
      const formErrors = validateDeliveryTicket(payload.deliveryTicket);
      setFormErrors(formErrors);
      animateToFormError();
      if (formErrors.hasError) return;
    }

    try {
      await updateDeliveryTicket({
        productOrderId: productOrderId,
        deliveryTicketId: deliveryTicketId,
        data: payload,
      }).unwrap();

      toast.success({
        title: '',
        message: getString(
          'dmeDeliveryPdfSavedSuccess',
          getString(`${LABEL_PREFIX}.deliveryTicket.title`),
        ),
      });
      nav.dme.deliveryTicket.view(productOrderId, deliveryTicketId, contactId);
    } catch (error) {
      toast.error({
        title: '',
        message: getString(
          'dmeDeliveryPdfSavedFail',
          getString(`${LABEL_PREFIX}.deliveryTicket.title`),
        ),
      });
    }
  };

  const handleSharePdf = async () => {
    try {
      const { orderItems } = deliveryTicketData;

      const shareDeliveryData = {
        productOrderId: productOrderId,
        orderItems: adaptOrdersForRequestBody(orderItems),
      };

      if (isAlreadyShared && !resendPdfModal) {
        setResendPdfModal(true);
        return;
      }
      await shareDeliveryTicketPdf(shareDeliveryData).unwrap();

      toast.success({
        title: '',
        message: getString(
          'dmeDeliveryPdfSharedSuccess',
          getString(`${LABEL_PREFIX}.deliveryTicket.title`),
        ),
      });
      nav.dme.deliveryTicket.view(productOrderId);
    } catch (error) {
      toast.error({
        title: '',
        message: getString(
          'dmeDeliveryPdfSharedFail',
          getString(`${LABEL_PREFIX}.deliveryTicket.title`),
        ),
      });
    }
  };

  if (isError) {
    return (
      <NoticeMessage
        title={getString(`${LABEL_PREFIX}.deliveryTicket.title`)}
        message={getErrorMessage(
          error.status,
          getString(`${LABEL_PREFIX}.deliveryTicket.title`),
        )}
        contactEmail={organizationContactEmail}
        contactPhone={organizationContactPhone}
      />
    );
  }

  if (isGetDeliveryTicketLoading) {
    return <FullPageLoader />;
  }

  return (
    <div className="delivery-ticket--container">
      <DmeHeader
        isEdit={isEdit}
        headerText={getString(`${LABEL_PREFIX}.deliveryTicket.title`)}
        handlePrint={redirectToPrint}
        handleSharePdf={deliveryPacketGenerated && handleSharePdf}
        isSharePdfLoading={isSharePdfLoading}
      />
      <DeliveryTicketForm
        isEdit={isEdit}
        data={deliveryTicketData}
        formErrors={formErrors?.errors}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
      />
      <ActionButtons
        isEnabled={!formErrors?.hasError && !isUpdateTicketLoading}
        handleSave={handleSave}
      />

      {resendPdfModal && (
        <ConfirmModal
          show={resendPdfModal}
          onCancel={() => {
            setResendPdfModal(false);
            return;
          }}
          onConfirm={() => {
            handleSharePdf();
            setResendPdfModal(false);
          }}
          headerContent={getString(
            'dmeDeliveryResendTitle',
            getString(`${LABEL_PREFIX}.deliveryTicket.title`),
          )}
          bodyContent={
            <div className="mb-8x">
              {getString(
                'dmeDeliveryResendContent',
                getString(`${LABEL_PREFIX}.deliveryTicket.title`),
              )}
            </div>
          }
          cancelText={getString('no')}
          confirmText={getString('yes')}
        />
      )}
    </div>
  );
};

export const ViewDeliveryTicketPage = () => {
  const { isStaff } = useContactId();
  return <DeliveryTicketPage isEdit={isStaff} />;
};
export const EditDeliveryTicketPage = () => (
  <DeliveryTicketPage isEdit={true} />
);
