import { QuestionnaireCheckedOption } from './RadioCheckOptions';
import {
  proofOfDelivery,
  questionnaireKeys,
} from '../validation/AddDeliveryTicket';

export const initialQuestionnaireData = [
  {
    id: 1,
    checked: QuestionnaireCheckedOption.NO,
  },
  {
    id: 2,
    checked: QuestionnaireCheckedOption.NO,
    [questionnaireKeys.datePatientGotTheEquipment]: '',
    [questionnaireKeys.whoPaidForIt]: '',
  },
  {
    id: 3,
    checked: QuestionnaireCheckedOption.NO,
    [questionnaireKeys.supplyRemainDays]: '',
  },
  {
    id: 4,
    checked: QuestionnaireCheckedOption.NO,
  },
  {
    id: 5,
    checked: QuestionnaireCheckedOption.NO,
  },
  {
    id: 6,
    checked: QuestionnaireCheckedOption.NO,
    [questionnaireKeys.agencyName]: '',
    [questionnaireKeys.agencyPhoneNumber]: '',
  },
  {
    id: 7,
    checked: QuestionnaireCheckedOption.NO,
    [questionnaireKeys.providerName]: '',
    [questionnaireKeys.providerPhoneNumber]: '',
    [questionnaireKeys.itemReceived]: '',
    [questionnaireKeys.dateReceived]: '',
    [questionnaireKeys.stillHasTheItem]: false,
    [questionnaireKeys.dateReturned]: '',
    [questionnaireKeys.returnReason]: '',
    [questionnaireKeys.sixtyDaysBreakNeeded]: '',
    [questionnaireKeys.agencyName]: '',
  },
];
export const initialProofOfDeliveryData = {
  [proofOfDelivery.checklist]: [],
  [proofOfDelivery.recipientName]: '',
  [proofOfDelivery.relationshipToBeneficiary]: '',
  [proofOfDelivery.reasonBeneficiaryIsUnableToSign]: '',
  [proofOfDelivery.date]: '',
};
