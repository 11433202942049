import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAppSettings } from 'context';
import {
  useGetRequiredDocumentQuery,
  useUpdateRequiredDocumentMutation,
} from 'redux/dme/dme.api';

import {
  OrderItem,
  ComponentStep,
  SignedDocumentDetail,
  RequiredDocumentResponse,
} from 'interface/productOrder';
import ABNForm from '../views/presentation/ABNForm';
import FullPageLoader from 'components/common/FullPageLoader';
import TermsAndConditions from '../views/presentation/TermsAndConditions';
import ElectronicSignature from '../views/presentation/ElectronicSignature';
import { ConfirmationPage } from '../views/presentation/ConfirmationPage/ConfirmationPage';
import { NoticeMessage } from 'components/COVID/views/DME/presentation/common/NoticeMessage/NoticeMessage';

import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { getDisplayDate } from 'util/DateAndTime';
import { getTrailingCommaValue } from 'util/string';
import { contactMasking, zipCodeMasking } from 'util/contactFormat';
import { InsuranceType, PaymentType } from 'constants/covid/insurance';
import { BeneficiaryRelation } from '../../../../constants/productOrder';

export const componentKeys = {
  esignature: 'esignature',
  termAndCondition: 'termAndCondition',
  abnForm: 'abnForm',
};

interface RouteParams {
  productOrderId: string;
}

export const DmeDocument = () => {
  const params = useParams<RouteParams>();
  const productOrderId = params?.productOrderId;

  const {
    data: requiredDocumentDataResponse,
    isLoading: isFetchingRequiredDocument,
  } = useGetRequiredDocumentQuery(productOrderId);

  const [updateRequiredDocument, { isLoading: isUpdatingRequiredDocument }] =
    useUpdateRequiredDocumentMutation();

  const {
    logoUrl,
    billingCity,
    billingState,
    billingZipCode,
    billingAddress,
    organizationName,
    organizationContactEmail,
    organizationContactPhone,
  } = useAppSettings();

  const formattedBillingZipCode = zipCodeMasking(billingZipCode);
  const formattedOrganizationContact = contactMasking(organizationContactPhone);

  const [requiredDocumentData, setRequiredDocumentData] =
    useState<RequiredDocumentResponse>();

  const [signedDocumentDetail, setSignedDocumentDetail] =
    useState<SignedDocumentDetail>({
      productOrderId,
      eSignature: '',
      tncFullName: '',
      tncAddress: '',
      tncDate: getDisplayDate(new Date()),
      tncRelation: BeneficiaryRelation.find(
        (relation) => relation.label === 'Self',
      )?.value,
      acceptTnc: false,
      tncReasonUnableToSign: '',
    });

  const [isMedicare, setIsMedicare] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [viewConfirmationPage, setViewConfirmationPage] = useState(false);
  const [currentStep, setCurrentStep] = useState<ComponentStep | null>(null);
  // State to dynamically populate required document and their data
  const [dynamicComponentOrder, setDynamicComponentOrder] = useState<
    ComponentStep[]
  >([]);
  const [payer, setPayer] = useState<string>('');

  const currentIndex = currentStep
    ? dynamicComponentOrder.indexOf(currentStep)
    : -1;

  // Conditionally Render Submit Button In ABN Form
  const isLastDocument = currentIndex === dynamicComponentOrder.length - 1;

  const nextLabel =
    dynamicComponentOrder[currentIndex + 1]?.type === componentKeys.abnForm
      ? (dynamicComponentOrder[currentIndex + 1]?.label ?? '')
      : dynamicComponentOrder[currentIndex + 1]?.type;

  const previousLabel =
    dynamicComponentOrder[currentIndex - 1]?.type === componentKeys.abnForm
      ? (dynamicComponentOrder[currentIndex - 1]?.label ?? '')
      : dynamicComponentOrder[currentIndex - 1]?.type;

  const goNext = () => {
    const currentIndex = dynamicComponentOrder.indexOf(currentStep!);
    if (currentIndex < dynamicComponentOrder.length - 1) {
      setCurrentStep(dynamicComponentOrder[currentIndex + 1]);
    }
  };

  const goBack = () => {
    const currentIndex = dynamicComponentOrder.indexOf(currentStep!);
    if (currentIndex > 0) {
      setCurrentStep(dynamicComponentOrder[currentIndex - 1]);
    }
  };

  // Generic handler for updating the signedDocumentDetail state
  const handleUpdateSignedDocumentDetail = (
    field: keyof SignedDocumentDetail | keyof OrderItem,
    value: string,
    orderItemId?: string,
  ) => {
    setSignedDocumentDetail((prevDetail) => {
      // If an orderItemId is provided, update or add to the orderItems array
      if (orderItemId && ['abnSignedDate', 'abnSignature'].includes(field)) {
        const updatedOrderItems = prevDetail.orderItems
          ? [...prevDetail.orderItems]
          : [];
        const itemIndex = updatedOrderItems.findIndex(
          (item) => item.orderItemId === orderItemId,
        );

        if (itemIndex !== -1) {
          // Update existing item
          updatedOrderItems[itemIndex] = {
            ...updatedOrderItems[itemIndex],
            [field]: value,
          };
        } else {
          // Add new item if it doesn't exist
          updatedOrderItems.push({
            orderItemId,
            abnSignedDate: '',
            abnSignature: '',
            [field]: value,
          });
        }

        return {
          ...prevDetail,
          orderItems: updatedOrderItems,
        };
      } else {
        // Update general fields
        return {
          ...prevDetail,
          [field]: value,
        };
      }
    });

    setErrors((prev) => ({
      ...prev,
      [field]: '',
    }));
  };

  const handleSubmit = async () => {
    try {
      const response =
        await updateRequiredDocument(signedDocumentDetail).unwrap();

      if (response.success) {
        setViewConfirmationPage(true);
      }
    } catch (error: unknown) {
      let errorMessage = getString('errorMessage');

      // Check if error is an object and has a message or data property
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          errorMessage = (error as { message: string }).message;
        } else if (
          'data' in error &&
          typeof (error as { data: { message: string } }).data === 'object'
        ) {
          errorMessage = (error as { data: { message: string } }).data.message;
        }
      }

      // Displaying the error in the toast notification
      toast.error({
        title: 'Error',
        message: errorMessage,
      });
    }
  };

  // Dynamically build component order based on productOrder data, set medicare status
  useEffect(() => {
    if (!requiredDocumentData) return;

    if (requiredDocumentData.insuranceType === InsuranceType.MEDICARE) {
      setIsMedicare(true);
    }

    if (requiredDocumentData.paymentType === PaymentType.WORKERS_COMP) {
      setPayer(requiredDocumentData.workersCompPayerName ?? '');
    }

    if (requiredDocumentData.paymentType === PaymentType.INSURANCE) {
      setPayer(requiredDocumentData.insurancePayerName ?? '');
    }

    const steps: ComponentStep[] = [];

    // Add esignature step if missing
    if (!requiredDocumentData.eSignature) {
      steps.push({ type: componentKeys.esignature });
    }

    // Add terms and conditions step if missing tnc file and full name
    if (
      !requiredDocumentData.tncAttachmentFile &&
      !requiredDocumentData.tncFullName
    ) {
      steps.push({ type: componentKeys.termAndCondition });
    }

    // Add abnForm steps for each order item missing abnSignature and file
    requiredDocumentData?.orderItems.forEach((item) => {
      if (!item.abnSignature && !item.file) {
        steps.push({
          type: componentKeys.abnForm,
          orderItem: item,
          label: getString('dme.documentation.pagination.abnForm', {
            hcpcs: item.hcpcsName,
          }) as string,
        });
      }
    });

    setDynamicComponentOrder(steps);
    if (steps.length > 0) setCurrentStep(steps[0]); // Initialize with the first step
  }, [requiredDocumentData]);

  useEffect(() => {
    if (!requiredDocumentDataResponse) return;

    setRequiredDocumentData(requiredDocumentDataResponse.data);
  }, [requiredDocumentDataResponse]);

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [currentStep]); // Empty dependency array means this effect runs once when the component mounts

  const renderComponent = () => {
    if (!currentStep)
      return (
        <NoticeMessage
          title={''}
          message={getString('dme.allDocsSignedMessage')}
          contactEmail={organizationContactEmail}
          contactPhone={organizationContactPhone}
        />
      );

    switch (currentStep.type) {
      case componentKeys.esignature:
        return (
          <ElectronicSignature
            goNext={goNext}
            nextLabel={nextLabel}
            isLastDocument={isLastDocument}
            handleSubmit={handleSubmit}
            organizationEmail={organizationContactEmail}
            signedDocumentDetail={signedDocumentDetail}
            onChange={handleUpdateSignedDocumentDetail}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case componentKeys.termAndCondition:
        return (
          <TermsAndConditions
            goNext={goNext}
            goBack={goBack}
            nextLabel={nextLabel}
            previousLabel={previousLabel}
            isLastDocument={isLastDocument}
            handleSubmit={handleSubmit}
            organizationName={organizationName}
            organizationContact={formattedOrganizationContact}
            signedDocumentDetail={signedDocumentDetail}
            onChange={handleUpdateSignedDocumentDetail}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case componentKeys.abnForm:
        return (
          <ABNForm
            primaryPayerName={payer}
            logoUrl={logoUrl}
            goBack={goBack}
            goNext={goNext}
            isMedicare={isMedicare}
            orderItem={currentStep.orderItem}
            productOrder={requiredDocumentData}
            handleSubmit={handleSubmit}
            isLastDocument={isLastDocument}
            nextAbnLabel={nextLabel}
            previousLabel={previousLabel}
            notifier={`
            ${getTrailingCommaValue(organizationName)}${getTrailingCommaValue(billingAddress)}${getTrailingCommaValue(billingCity)}${getTrailingCommaValue(billingState)}${getTrailingCommaValue(formattedBillingZipCode)}${formattedOrganizationContact}
          `
              .replace(/\n/g, ' ')
              .trim()}
            signedOrderItemData={
              signedDocumentDetail.orderItems?.find(
                (orderItem) =>
                  orderItem.orderItemId === currentStep.orderItem?.orderItemId,
              ) ?? {
                abnSignature: '',
                abnSignedDate: '',
                orderItemId: '',
              }
            }
            onChange={handleUpdateSignedDocumentDetail}
            errors={errors}
            setErrors={setErrors}
          />
        );
      default:
        return null;
    }
  };

  if (isFetchingRequiredDocument || isUpdatingRequiredDocument) {
    return <FullPageLoader />;
  }

  return (
    <>
      {viewConfirmationPage ? (
        <ConfirmationPage
          documents={dynamicComponentOrder}
          isMedicare={isMedicare}
          productOrder={requiredDocumentData}
          organizationName={organizationName}
          primaryPayerName={payer}
          signedDocumentDetail={signedDocumentDetail}
        />
      ) : (
        renderComponent()
      )}
    </>
  );
};
