import React, { useState, useEffect, useMemo } from 'react';

import { useNavigationHelper } from 'hooks/useNavigationHelper';
import {
  useAppSettings,
  useDetailContext,
  useSelectionsContext,
} from 'context';

import LinkToPage from 'components/common/LinkToPage';
import ReCaptcha from 'components/COVID/common/ReCaptcha';
import AlertMessage from 'components/COVID/common/AlertMessage';
import ActivityIndicator from 'components/common/ActivityIndicator';
import SelectBox from 'components/common/SelectBox';
import BackToPage from 'components/COVID/common/BackToPage/BackToPage';
import CancelAppointment from 'components/COVID/common/CancelAppointment';
import SkeletonLoadingContainer from 'components/common/SkeletonLoadingContainer';

import LoadingModal from '../presentation/LoadingModal';
import SelectedClinic from '../presentation/SelectedClinic';
import VaccineInformation from '../presentation/VaccineInformation';
import SignatureInput from '../../../../common/SignatureInput';
import DTPicker from '../../NewClinicAndTiming/presentation/DTPicker';
import { useClinicAndTiming } from '../../NewClinicAndTiming/ClinicAndTiming';
import TermsAndConditions from '../../PaymentInfo/presentation/TermsAndConditions';
import { EditServiceModal } from '../../EditService/presentation/EditServiceModal';
import PatientGuardianInfo from '../../PatientInformation/presentation/PatientGuardianInfo';
import { validateGuardianInfo } from '../../PatientInformation/container/PatientInfoValidation';
import { GroupConfirmationCardWrapper } from '../../GroupAppointment/container/GroupConfirmationCardWrapper';

import regexPattern from 'constants/regexPattern';
import CUSTOM_ERROR_CODE from 'constants/customHttpErrorCode';
import {
  ConsentedByOptions,
  ConsentedBy,
  PaymentOptions,
} from 'constants/patientInfo';

import { getString } from 'util/lang';
import DateTimeUtils from 'util/DateAndTime';
import { handleError } from 'util/errorHandler';
import { replaceArrayElementByIndex } from 'util/array';
import { formatFormInput } from 'util/formatFormInput';
import { changeTitle } from 'util/siteInfo';

const COMPONENT_STATE_KEY = {
  acceptanceInfo: 'acceptanceInfo',
  isSignatureCanvasOpen: 'isSignatureCanvasOpen',
  areTermsAccepted: 'areTermsAccepted',
  isUnderEighteen: 'isUnderEighteen',
  isUnderNineteen: 'isUnderNineteen',
  isUnderMinimumAge: 'isUnderMinimumAge',
  captchaKey: 'captchaKey',
  isModalOpen: 'isModalOpen',
  dateSelectionState: 'dateSelectionState',
  isLoadingModalOpen: 'isLoadingModalOpen',
  isCaptchaAttempted: 'isCaptchaAttempted',
  consentedBy: 'consentedBy',
  showInsuraceCoverageAlert: 'showInsuraceCoverageAlert',
  insuranceCoveredServiceName: 'insuranceCoveredServiceName',
};

const GUARDIAN_INFO_KEYS = [
  'guardianFirstName',
  'guardianLastName',
  'guardianMiddleName',
];

/**
 *
 * @param {{
 *  confirmAndSubmit: () => Promise<void>
 *  toNextPage: () => void
 *  goBack: () => void
 *  disableEditSchedule?: boolean
 *  disableEditService?: boolean
 *  isGroupAppointment?: boolean
 * }} props
 * @returns
 */
const Confirmation = (props) => {
  const {
    confirmAndSubmit,
    toNextPage,
    goBack,
    disableEditService = false,
    disableEditSchedule = false,
    isGroupAppointment = false,
  } = props;
  changeTitle('Confirmation');

  const nav = useNavigationHelper();
  const { isCaptchaEnabled } = useAppSettings();
  const { selectedServices, selectedClinic } = useSelectionsContext();
  const { details, setDetails, disableNavForSuccessPage } = useDetailContext();

  const [componentState, setComponentState] = useState({
    acceptanceInfo: details.acceptanceInfo[details.currentPatientIndex] ?? {},
    isSignatureCanvasOpen: false,
    areTermsAccepted: false,
    isUnderEighteen: false,
    isUnderNineteen: false,
    isUnderMinimumAge: false,
    captchaKey: null,
    isModalOpen: false,
    dateSelectionState: {
      conflict: false, // show slot conflict message
      visible: false, // show calendar without conflict message
      apiEnabled: false, // NOTE: need this to prevent unnecessary api calls
    },
    isLoadingModalOpen: false,
    isCaptchaAttempted: false,
    consentedBy:
      details?.commonAppointmentDetails[details.currentPatientIndex]
        ?.consentedBy ?? ConsentedBy.PATIENT,
    guardianInfo: {},
    errors: {},
    showInsuraceCoverageAlert: false,
    insuranceCoveredServiceName: '',
  });

  /**
   *
   * @param {string} key The attribute who corresponding state value is to be updated
   * @param {any} value The value corresponding to the key to be updated
   */
  const updateComponentState = (key, value) => {
    if (Object.keys(COMPONENT_STATE_KEY).includes(key)) {
      setComponentState((prev) => ({ ...prev, [key]: value }));
    }
  };

  const {
    saveAppointment,
    DateAndTimeSelectionProps,
    isAppointmentValid,
    isClinicDaysEmpty,
  } = useClinicAndTiming({
    enabled: componentState.dateSelectionState.apiEnabled,
  });

  const {
    appointments,
    appointmentDetails,
    firstPageLoaded: isFirstPageLoaded,
    isVaccineListFetching: isLanguageUpdating,
    patientInfo,
    localizedWebContents,
    isLocalizedWebContentsFetching,
    loading,
    currentPatientIndex,
    insuranceCoverageList,
  } = details;

  const selectedServiceNames = selectedServices.map(
    ({ service }) => service?.name,
  );

  const currentPatientInfo = patientInfo[currentPatientIndex];
  const primarySubService = selectedServices?.[0]?.subService;
  const primaryServiceType = selectedServices?.[0]?.serviceType;

  const showTermsAndConditions = localizedWebContents.some(
    (content) =>
      content.termsAndCondition || content.termsAndConditionsAcceptance,
  );

  /**
   * @desc Check Terms and Condition Validation upon each acceptedTerms update
   */
  useEffect(() => {
    const isValid = validateTermsAndConditions(
      componentState.acceptanceInfo.acceptedTerms,
    );
    setComponentState((prev) => ({ ...prev, areTermsAccepted: isValid }));
  }, [componentState.acceptanceInfo.acceptedTerms]);

  /**
   * @desc Update global state upon all accepted terms and signature
   */
  useEffect(() => {
    if (
      componentState.areTermsAccepted &&
      componentState.acceptanceInfo.signatureUrl
    ) {
      updateGlobalState();
    }
  }, [
    componentState.acceptanceInfo.signatureUrl,
    componentState.areTermsAccepted,
  ]);

  /**
   * @desc set AgeComponent state upon first render
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    const { birthDay, birthMonth, birthYear } = currentPatientInfo;
    if (birthDay && birthMonth && birthYear) {
      const birthDate = `${birthMonth}-${birthDay}-${birthYear}`;
      const isUnderMinimumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isUnderMinimumAge(
          birthDate,
          subService.minAgeRequirement,
        ),
      );
      const isAboveMaximumAge = selectedServices.some(({ subService }) =>
        DateTimeUtils.isAboveMaximumAge(
          birthDate,
          subService.maxAgeRequirement,
        ),
      );

      const isUnderEighteen = DateTimeUtils.isUnderEighteen(birthDate);
      const isUnderNineteen = DateTimeUtils.isUnderNineteen(birthDate);
      const consentedBy = isUnderNineteen
        ? ConsentedBy.GUARDIAN
        : ConsentedBy.PATIENT;
      setComponentState((prev) => ({
        ...prev,
        isUnderMinimumAge,
        isAboveMaximumAge,
        isUnderEighteen,
        isUnderNineteen,
        consentedBy,
      }));
    }

    // check mode of payment too
    if (
      selectedServices.length > 1 &&
      appointmentDetails[0]?.paymentType === PaymentOptions.Insurance
    ) {
      const { status, message } = checkBothServiceInsuranceCoverageStatus;
      updateComponentState(
        COMPONENT_STATE_KEY.showInsuraceCoverageAlert,
        !status,
      );
      updateComponentState(
        COMPONENT_STATE_KEY.insuranceCoveredServiceName,
        message,
      );
    }
  }, []);

  /**
   *
   * @returns Boolean representing insurance coverage by both services
   */
  const checkBothServiceInsuranceCoverageStatus = useMemo(() => {
    let firstServiceInsuraceCoverage = false;
    let secondServiceInsuranceCoverage = false;
    insuranceCoverageList[currentPatientIndex].forEach((insuranceCoverage) => {
      const [firstServiceCoverage, secondServiceCoverage] = insuranceCoverage;
      firstServiceInsuraceCoverage ||= firstServiceCoverage;
      secondServiceInsuranceCoverage ||= secondServiceCoverage;
    });

    if (firstServiceInsuraceCoverage && secondServiceInsuranceCoverage) {
      return {
        status: true,
        message: `${selectedServiceNames[0]} and ${selectedServiceNames[1]}`,
      };
    }
    return {
      status: false,
      message:
        (firstServiceInsuraceCoverage && selectedServiceNames[0]) ||
        (secondServiceInsuranceCoverage && selectedServiceNames[1]),
    };
  }, [insuranceCoverageList, selectedServiceNames]);

  /**
   *
   * @returns Boolean representing insurance coverage status for atleast one
   */
  const getInsuranceCoverageStatus = useMemo(() => {
    if (appointmentDetails[0]?.paymentType !== PaymentOptions.Insurance) {
      return false;
    }
    let firstServiceInsuraceCoverage = false;
    let secondServiceInsuranceCoverage = false;
    insuranceCoverageList[currentPatientIndex].forEach((insuranceCoverage) => {
      const [firstServiceCoverage, secondServiceCoverage] = insuranceCoverage;
      firstServiceInsuraceCoverage ||= firstServiceCoverage;
      secondServiceInsuranceCoverage ||= secondServiceCoverage;
    });

    return firstServiceInsuraceCoverage || secondServiceInsuranceCoverage;
  }, [insuranceCoverageList, selectedServiceNames]);

  const onSaveSignature = (image, compressed) => {
    setComponentState((prev) => ({
      ...prev,
      acceptanceInfo: {
        ...componentState.acceptanceInfo,
        signatureUrl: image,
        compressedSignature: compressed === undefined ? null : compressed,
      },
    }));
  };

  const onAcceptanceInfoChange = (key, value) => {
    if (key == null || value == null) return;

    const { acceptanceInfo } = componentState;

    setComponentState((prev) => ({
      ...prev,
      acceptanceInfo: {
        ...acceptanceInfo,
        acceptedTerms: {
          ...acceptanceInfo.acceptedTerms,
          [key]: value,
        },
      },
    }));
  };

  /**
   * Clear error for input field with that key
   */
  const resetError = (key) => {
    const newError = { ...componentState.errors };
    newError[key] = null;
    return newError;
  };

  const __getInputFieldErrors = () => {
    const { guardianInfo } = componentState;

    let errors = {};
    if (componentState.isUnderNineteen) {
      const { errors: guardianInfoErrors } = validateGuardianInfo(guardianInfo);
      errors = { ...guardianInfoErrors };
    }

    return errors;
  };
  /**
   * Animation to error
   */
  const animateToFormError = () => {
    setTimeout(() => {
      const errorField = document.getElementsByClassName('form-error')[0];

      if (errorField) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: errorField.offsetTop - 200,
        });
      }
    });
  };

  const checkFormValidity = async (isAutoTrigger) => {
    const errors = __getInputFieldErrors();

    const errorList = Object.keys(errors).filter((key) => errors[key]);

    setComponentState((prev) => ({
      ...prev,
      errors,
    }));

    if (errorList.length > 0) {
      if (!isAutoTrigger) {
        animateToFormError();
      }
      return false;
    }
    return true;
  };

  const onGuardianInfoChange = (key, value) => {
    if (key == null || value == null) return;

    if (
      GUARDIAN_INFO_KEYS.includes(key) &&
      value !== '' &&
      !value.match(regexPattern.nameField)
    )
      return;

    const { guardianInfo } = componentState;

    value = formatFormInput(key, value);
    // Do not process further if new value equals to old value
    if (guardianInfo[key] === value) return;

    const newChange = { [key]: value };

    let errors = resetError(key);

    setComponentState((prev) => ({
      ...prev,
      guardianInfo: { ...guardianInfo, ...newChange },
      errors,
    }));
  };

  const updateGlobalState = () => {
    const commonAppointmentDetails = {
      ...details.commonAppointmentDetails[currentPatientIndex],
      ...componentState.acceptanceInfo,
      consentedBy: componentState.consentedBy,
    };

    const currentPatientInfo = {
      ...details.patientInfo[currentPatientIndex],
      ...componentState.guardianInfo,
    };

    const appointmentDetails = selectedServices.map((s, idx) => {
      return {
        ...details.appointmentDetails[idx],
        ...componentState.acceptanceInfo,
        consentedBy: componentState.consentedBy,
      };
    });

    const globalAcceptanceInfo = replaceArrayElementByIndex(
      details.acceptanceInfo,
      currentPatientIndex,
      componentState.acceptanceInfo,
    );

    const globalPatientInfo = replaceArrayElementByIndex(
      details.patientInfo,
      currentPatientIndex,
      currentPatientInfo,
    );

    setDetails({
      commonAppointmentDetails: replaceArrayElementByIndex(
        details.commonAppointmentDetails,
        currentPatientIndex,
        commonAppointmentDetails,
      ),
      appointmentDetails: appointmentDetails,
      acceptanceInfo: globalAcceptanceInfo,
      patientInfo: globalPatientInfo,
    });
  };

  /**
   * @param {{bool}} val: Show or hide signature canvas
   */
  const setIsSignatureCanvasOpen = (val) => {
    setComponentState((prev) => ({
      ...prev,
      isSignatureCanvasOpen: val,
    }));
  };

  useEffect(() => {
    if (!isFirstPageLoaded) {
      nav.resetToFirstPage();
    }
  }, [nav, isFirstPageLoaded]);

  const onCaptchaChange = (captchaResponseKey) => {
    updateComponentState(COMPONENT_STATE_KEY.captchaKey, captchaResponseKey);
  };

  const onDateTimeEdit = (doseNumber) => {
    updateComponentState(COMPONENT_STATE_KEY.dateSelectionState, {
      ...componentState.dateSelectionState,
      visible: true,
      apiEnabled: true,
    });
  };

  const confirmAndSubmitAppointment = async () => {
    const isFormValid = await checkFormValidity();
    if (!isFormValid) {
      return;
    }
    // Global write component state to app state
    updateGlobalState();

    if (isCaptchaEnabled && !componentState.captchaKey) {
      updateComponentState(COMPONENT_STATE_KEY.isCaptchaAttempted, true);
      return;
    }
    updateComponentState(COMPONENT_STATE_KEY.isLoadingModalOpen, true);
    try {
      // NOTE: prevent unncessary api call
      updateComponentState(COMPONENT_STATE_KEY.dateSelectionState, {
        ...componentState.dateSelectionState,
        apiEnabled: false,
      });
      componentState.dateSelectionState.visible && saveAppointment();
      setDetails({ captchaResponseKey: componentState.captchaKey });
      await confirmAndSubmit();
      disableNavForSuccessPage();
      toNextPage();
    } catch (error) {
      isCaptchaEnabled && window.grecaptcha.reset();

      onCaptchaChange(null);
      const errorCode = error?.response?.status;
      if (errorCode === CUSTOM_ERROR_CODE.NO_SLOT_AVAILABLE) {
        updateComponentState(COMPONENT_STATE_KEY.dateSelectionState, {
          conflict: true,
          visible: true,
          apiEnabled: true,
        });
        return;
      } else if (
        errorCode === CUSTOM_ERROR_CODE.SINGLE_USECODE_USED ||
        errorCode === CUSTOM_ERROR_CODE.SINGLE_USECODE_EXPIRED ||
        errorCode === CUSTOM_ERROR_CODE.SINGLE_USECODE_NOT_FOUND
      ) {
        handleError(error);
        return;
      }
      handleError(error);
    } finally {
      onCaptchaChange(null);
      updateComponentState(COMPONENT_STATE_KEY.isLoadingModalOpen, false);
    }
  };

  const openModal = () => {
    updateComponentState(COMPONENT_STATE_KEY.isModalOpen, true);
  };

  const closeModal = () => {
    updateComponentState(COMPONENT_STATE_KEY.isModalOpen, false);
  };

  /**
   *
   * Checks to see if all the terms and conditions are accepted
   * Returns true if all terms are checked
   * Returns false if all terms are not checked
   *
   * @param {*} acceptedTermsList
   * @returns {boolean}
   */
  const validateTermsAndConditions = (acceptedTermsList) => {
    if (!acceptedTermsList) return false;
    let areTermsAccepted = true;
    const { termsAndConditionsAcceptance } =
      details.localizedWebContents[0] || [];
    termsAndConditionsAcceptance?.forEach((terms) => {
      if (!acceptedTermsList[terms.id]) {
        areTermsAccepted = false;
      }
    });
    return areTermsAccepted;
  };

  const isSubmitBtnEnabled =
    !isLanguageUpdating &&
    (componentState.dateSelectionState.visible ? isAppointmentValid : true);

  if (!isFirstPageLoaded) {
    return null;
  }

  return (
    <>
      <BackToPage text={getString('backToPaymentPage')} onClick={goBack} />
      {isGroupAppointment && <GroupConfirmationCardWrapper />}
      {getInsuranceCoverageStatus && (
        <AlertMessage
          isVisible
          type="success"
          className="mb-6x"
          message={getString('insuranceEligibilitySuccessMessage')}
        />
      )}

      {componentState.showInsuraceCoverageAlert && (
        <AlertMessage
          isVisible
          type="info"
          className="mb-6x"
          message={getString('singleInsuranceCoverageMessage', {
            serviceName: componentState.insuranceCoveredServiceName,
          })}
        />
      )}
      <section className="schedule-appointment section__margin">
        <h2>{getString('confirmAppointmentDetails')}</h2>
        <section className="border-radius border">
          {isLanguageUpdating ? (
            <div className="section__container py-26x">
              <ActivityIndicator />
            </div>
          ) : (
            <React.Fragment>
              <SelectedClinic selectedClinic={selectedClinic} />
              <hr />
              <VaccineInformation
                selectedServices={selectedServices}
                appointments={appointments}
                subService={primarySubService}
                serviceType={primaryServiceType}
                onEditService={disableEditService ? null : openModal}
                onDateTimeEdit={disableEditSchedule ? null : onDateTimeEdit}
              />

              {(componentState.dateSelectionState.conflict ||
                componentState.dateSelectionState.visible) && (
                <div className="section__container pt-0x">
                  {componentState.dateSelectionState.conflict && (
                    <AlertMessage
                      className="mb-6x"
                      isVisible
                      type="danger"
                      message={
                        isClinicDaysEmpty
                          ? getString('slotsFullyBooked')
                          : getString('selectedSlotNotAvailable')
                      }
                    />
                  )}

                  <div className="vaccine-selection vaccine-selection__confirmation">
                    <DTPicker {...DateAndTimeSelectionProps} />
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </section>
      </section>

      {showTermsAndConditions && (
        <SkeletonLoadingContainer loading={isLocalizedWebContentsFetching}>
          <TermsAndConditions
            localizedWebContents={localizedWebContents}
            selectedServices={selectedServices}
            onChange={onAcceptanceInfoChange}
            value={componentState.acceptanceInfo?.acceptedTerms ?? {}}
          />
        </SkeletonLoadingContainer>
      )}

      <div className="patient-form-group border-rounded section__margin">
        <SelectBox
          id="consentedBy"
          label="Consented By"
          value={componentState.consentedBy}
          required
          options={ConsentedByOptions}
          onChange={(e) =>
            updateComponentState(
              COMPONENT_STATE_KEY.consentedBy,
              e.target.value,
            )
          }
          dataqa="consented-by"
        />
      </div>

      {!componentState.isUnderMinimumAge && componentState.isUnderNineteen && (
        <PatientGuardianInfo
          onChangeInput={onGuardianInfoChange}
          patientInfo={componentState.guardianInfo}
          errors={componentState.errors}
        />
      )}

      <SignatureInput
        onDraw={onSaveSignature}
        isOpen={componentState.isSignatureCanvasOpen}
        onClick={setIsSignatureCanvasOpen}
        source={componentState.acceptanceInfo.signatureUrl}
        isUnderMinimumAge={componentState.isUnderMinimumAge}
        isUnderEighteen={componentState.isUnderEighteen}
        isUnderNineteen={componentState.isUnderNineteen}
      />

      <AlertMessage
        isVisible
        type="warning"
        className="mb-6x"
        message={getString('submitAppointmentMessage')}
      />

      {isCaptchaEnabled && (
        <ReCaptcha
          onCaptchaChange={onCaptchaChange}
          isCaptchaValid={
            componentState.isCaptchaAttempted
              ? !!componentState.captchaKey
              : true
          }
        />
      )}
      <div className="row">
        <div className="col-3-sm order-1">
          <CancelAppointment />
        </div>
        <div className="col-9-sm order-2-sm">
          <LinkToPage
            onClick={confirmAndSubmitAppointment}
            label={getString('confirmAndSubmit')}
            title={getString('submitTitle')}
            enabled={
              componentState.areTermsAccepted &&
              componentState.acceptanceInfo.signatureUrl &&
              isSubmitBtnEnabled
            }
            loading={loading}
            dataqa="submit-appointment"
          />
        </div>
      </div>
      {componentState.isModalOpen && (
        <EditServiceModal onClose={closeModal} onConfirm={nav.toEditService} />
      )}
      {componentState.isLoadingModalOpen && <LoadingModal isClosable={false} />}
    </>
  );
};

export default Confirmation;
