import { getString } from 'util/lang';
import styles from './OperatingInstruction.module.scss';
import FormHeader from '../../common/FormHeader/FormHeader';

const LABEL_PREFIX = 'dme.deliveryPacketForm.operatingInstructions';

const OperatingInstruction = () => {
  const operatingInstructions = getString(
    `${LABEL_PREFIX}.equipmentInstructions`,
  );
  return (
    <div className={`group-container ${styles.operatingInstructionWrap}`}>
      <FormHeader text={getString(`${LABEL_PREFIX}.title`)} />
      <div className={styles.operatingInstruction}>
        {operatingInstructions.map((operatingInstruction, index) => (
          <div key={index} className={styles.categoryWrap}>
            <p className={styles.category}>{operatingInstruction.category}</p>
            <ol className={styles.sectionContainer}>
              {operatingInstruction.sections.map((section, index) => (
                <li key={index} className={styles.sectionWrap}>
                  {!!section.name?.length && (
                    <span className={styles.sectionName}>{section.name}: </span>
                  )}
                  {typeof section.content === 'string' ? (
                    <p className={styles.contentText}>{section.content}</p>
                  ) : (
                    <ol className={styles.contentList}>
                      {section.content.map((content, index) => (
                        <li key={index} className={styles.contentListItem}>
                          {content}
                        </li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
            </ol>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OperatingInstruction;
