import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';
import LineBreak from '../../common/LineBreak/LineBreak';
import AlertMessage from 'components/COVID/common/AlertMessage';

const LABEL_PREFIX = 'dme.deliveryTicket.homeAssessmentAndSafety';

// Define the checklist array with proper types
const homeAssessmentChecklist = [
  {
    key: 'unclutteredPathways',
    question: getString(`${LABEL_PREFIX}.unclutteredPathways`),
  },
  {
    key: 'safeOperatingEquiment',
    question: getString(`${LABEL_PREFIX}.safeOperatingEquiment`),
  },
  {
    key: 'bathroomAssessed',
    question: getString(`${LABEL_PREFIX}.bathroomAssessed`),
  },
  {
    key: 'areaRugs',
    question: getString(`${LABEL_PREFIX}.areaRugs`),
  },
  {
    key: 'fireSafetyAccessed',
    question: getString(`${LABEL_PREFIX}.fireSafetyAccessed`),
  },
  {
    key: 'cordsAndAdapters',
    question: getString(`${LABEL_PREFIX}.cordsAndAdapters`),
  },
  {
    key: 'prCgSafety',
    question: getString(`${LABEL_PREFIX}.prCgSafety`),
  },
  {
    key: 'safeElectricOutlet',
    question: getString(`${LABEL_PREFIX}.safeElectricOutlet`),
  },
  {
    key: 'gettingInAndOut',
    question: getString(`${LABEL_PREFIX}.gettingInAndOut`),
  },
  {
    key: 'other',
    question: getString(`${LABEL_PREFIX}.other`),
  },
];

// Define the accumulator type explicitly and use it in the reduce function
export const homeAssessmentToQuestionMap = homeAssessmentChecklist.reduce(
  (acc, curr) => {
    const { key, question } = curr;
    acc[key] = question;
    return acc;
  },
  {},
);

export const initialHomeAssessmentValues = homeAssessmentChecklist.map(
  ({ key }) => ({
    key,
    checked: false,
    additionalInfo: '',
  }),
);

/**
 * HomeAssessmentAndSafety component - Delivery Ticket - DME
 * @param {object} homeAssessmentData
 * @param {function} setDeliveryTicketHandler
 */
const HomeAssessmentAndSafety = ({
  homeAssessmentData,
  setDeliveryTicketHandler,
  isEdit,
}) => {
  return (
    <div className="delivery-ticket__acknowledgement--wrap">
      <div className="delivery-ticket__acknowledgement--title">
        <h2>{getString(`${LABEL_PREFIX}.title`)}</h2>
      </div>
      <AlertMessage
        type="info"
        message={getString(`${LABEL_PREFIX}.info`)}
        isVisible={true}
        className="mt-4x mb-2x"
      />
      <p className="">{getString(`${LABEL_PREFIX}.content`)}</p>
      <div className="row pl-2x ml-4x">
        {homeAssessmentData.map((document) => {
          const { key, checked, additionalInfo } = document;
          const question = homeAssessmentToQuestionMap[key];

          return (
            <div
              key={key}
              className={`col-12-md custom-check px-0x mb-3x d-flex gap-4`}
            >
              <div>
                <input
                  autoComplete="off"
                  type="checkbox"
                  id={key}
                  name={question}
                  disabled={!isEdit}
                  onChange={() => {
                    setDeliveryTicketHandler(
                      homeAssessmentData.map((datum) => {
                        if (datum.key === key) {
                          return {
                            ...datum,
                            checked: !datum.checked,
                          };
                        }
                        return datum;
                      }),
                      'homeAssessmentData',
                    );
                  }}
                  value={checked}
                  checked={checked}
                  className="row delivery-ticket__document_checklist-box"
                />
                <label
                  htmlFor={key}
                  className="row delivery-ticket__document_checklist-label"
                >
                  <FiCheck /> {question}
                </label>
              </div>
              {key === 'other' && (
                <input
                  className="border-none border-bottom"
                  id="other"
                  value={additionalInfo}
                  type="text"
                  onChange={(e) =>
                    setDeliveryTicketHandler(
                      homeAssessmentData.map((datum) => {
                        if (datum.key === key) {
                          return {
                            ...datum,
                            additionalInfo: e.target.value,
                          };
                        }
                        return datum;
                      }),
                      'homeAssessmentData',
                    )
                  }
                />
              )}
            </div>
          );
        })}
      </div>
      <LineBreak />
    </div>
  );
};

export default HomeAssessmentAndSafety;
