import { getString } from 'util/lang';

export const BeneficiaryRelation = [
  {
    label: getString('relationshipSelf'),
    value: 622490000,
  },
  {
    label: getString('relationPowerOfAttorney'),
    value: 622490001,
  },
];
