import styles from './PatientInfo.module.scss';
import { getString } from 'util/lang';

const LABEL_PREFIX = 'dme.deliveryTicket';

const PatientInfo = ({ data, className }) => {
  return (
    <div className={`${styles.pharmaInfo} ${className}`}>
      <h3>{getString(`${LABEL_PREFIX}.patientDetails.title`)}</h3>
      <p>
        {getString(`${LABEL_PREFIX}.patientDetails.name`)}: {data?.name}
      </p>
      <p>
        {getString(`${LABEL_PREFIX}.patientDetails.dob`)}: {data?.dob}
      </p>
      <p>
        {getString(`${LABEL_PREFIX}.patientDetails.phone`)}: {data?.phone}
      </p>
    </div>
  );
};

export default PatientInfo;
