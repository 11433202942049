import Button from 'components/common/Button';
import styles from './ActionButtons.module.scss';

const ActionButtons = ({ handleSave = () => {}, isEnabled = true }) => {
  return (
    <div
      className={`group-container d-flex justify-content-center mb-4x mt-3x ${styles.actionButton}`}
    >
      <Button
        title="Save"
        isEnabled={isEnabled}
        onClick={handleSave}
        className={`btn btn--regular btn-primary--outline btn--rounded ${styles.buttonSave}`}
        isActive
        label="Save"
      />
    </div>
  );
};

export default ActionButtons;
