import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';
import LineBreakPdfView from '../../common/LineBreak/LineBreakPdfView';
import { iconCheckboxChecked, iconCheckboxUnchecked } from 'assets/images';
import DMESignaturePdfView from '../../common/DMESignature/DMESignaturePdfView';

const LABEL_PREFIX = 'dme.deliveryTicket.proofOfAcknowledgement';

const ProofOfDeliveryAcknowledgementPdfView = ({
  proofOfDeliveryData,
  consent,
}) => {
  return (
    <>
      <LineBreakPdfView />
      <View style={styles.container}>
        <View style={styles.headerWrap}>
          <Text style={styles.headerText}>
            {getString(`${LABEL_PREFIX}.title`)}
          </Text>
        </View>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.description`)}
        </Text>
        <View style={styles.listWrap}>
          <View style={styles.list}>
            <View style={{ marginLeft: 10, marginBottom: 4, marginRight: 4 }}>
              {proofOfDeliveryData?.checklist?.find(
                (data) => data.key === 'equipmentProperlyFitted',
              )?.checked ? (
                <Image
                  src={iconCheckboxChecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              ) : (
                <Image
                  src={iconCheckboxUnchecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              )}
            </View>
            <Text style={styles.text}>
              {getString(`${LABEL_PREFIX}.listFirst`)}
            </Text>
          </View>
          <View style={styles.list}>
            <View style={{ marginLeft: 10, marginBottom: 4, marginRight: 4 }}>
              {proofOfDeliveryData?.checklist?.find(
                (data) => data.key === 'receivedWrittenInstruction',
              )?.checked ? (
                <Image
                  src={iconCheckboxChecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              ) : (
                <Image
                  src={iconCheckboxUnchecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              )}
            </View>
            <Text style={styles.text}>
              {getString(`${LABEL_PREFIX}.listSecond`)}
            </Text>
          </View>
          <View style={styles.list}>
            <View style={{ marginLeft: 10, marginBottom: 4, marginRight: 4 }}>
              {proofOfDeliveryData?.checklist?.find(
                (data) => data.key === 'understandReturnsPolicy',
              )?.checked ? (
                <Image
                  src={iconCheckboxChecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              ) : (
                <Image
                  src={iconCheckboxUnchecked}
                  alt="checkbox-checked"
                  style={styles.img}
                />
              )}
            </View>
            <Text style={styles.text}>
              {getString(`${LABEL_PREFIX}.listThird`)}
            </Text>
          </View>
        </View>
        <View style={styles.recipientDetails}>
          <Text style={styles.recipientText}>
            {getString(`${LABEL_PREFIX}.recipientName`)}:{' '}
            <Text>{proofOfDeliveryData?.recipientName}</Text>
          </Text>
          <Text style={styles.recipientText}>
            {getString(`${LABEL_PREFIX}.relationshipToBeneficiary.label`)}:{' '}
            <Text>{proofOfDeliveryData?.relationshipToBeneficiary ?? ''}</Text>
          </Text>
          <Text style={styles.recipientText}>
            {getString(`${LABEL_PREFIX}.reasonBeneficiaryIsUnableToSign.label`)}
            :{' '}
            <Text>{proofOfDeliveryData?.reasonBeneficiaryIsUnableToSign}</Text>
          </Text>
        </View>
        <DMESignaturePdfView
          title={getString(`${LABEL_PREFIX}.signature`)}
          style={{
            marginTop: 15,
          }}
          signature={consent?.signature}
          signedDate={consent?.signedDate}
          showDate={true}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrap: {
    padding: '8px 11px 8px 11px',
    border: '1px solid #E1DFDD',
    backgroundColor: '#FAF9F8',
    marginBottom: 8,
  },
  headerText: {
    fontSize: 13,
    fontWeight: 600,
    color: '#323130',
    textAlign: 'center',
  },
  listWrap: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 4,
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    marginBottom: 4,
  },
  recipientText: {
    fontSize: 9,
    fontWeight: 600,
    color: '#323130',
    marginBottom: 4,
  },
  img: {
    height: 13,
    width: 13,
  },
  recipientDetails: {
    marginTop: 8,
  },
});

export default ProofOfDeliveryAcknowledgementPdfView;
