import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getString } from 'util/lang';
import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';
import PharmacyInfoPdfView from '../../common/PharmaInfo/PharmaInfoPdfView';

const LABEL_PREFIX = 'dme.deliveryPacketForm.billOfRightsAndResponsibilities';
const rightsData = getString(`${LABEL_PREFIX}.rights.data`);

const BillOfRightsAndResponsibilitiesPdfView = ({ organizationDetail }) => {
  const { organizationName, organizationContactPhone, address } =
    organizationDetail;
  const responsibilitiesData = JSON.parse(
    getString(`${LABEL_PREFIX}.responsibilities.data`, {
      companyName: organizationName,
    }),
  );

  return (
    <View style={styles.container}>
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />
      <View style={{ marginTop: 12 }} />
      <PharmacyInfoPdfView
        name={organizationName}
        address={address}
        contact={organizationContactPhone}
      />

      <Text style={styles.desc}>
        {getString(`${LABEL_PREFIX}.description`)}
      </Text>

      <View style={styles.rightsAndResponsibilitiesWrap}>
        <View style={styles.rightsAndResponsibilities}>
          <Text style={styles.h3}>
            {getString(`${LABEL_PREFIX}.rights.title`)}
          </Text>
          <View style={styles.listWrap}>
            {rightsData.map((right, index) => (
              <View style={styles.list} key={index}>
                <View style={styles.bullet}>
                  <Text>•</Text>
                </View>
                <Text style={styles.text}>{right}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.rightsAndResponsibilities}>
          <Text style={styles.h3}>
            {getString(`${LABEL_PREFIX}.responsibilities.title`)}
          </Text>
          <View style={styles.listWrap}>
            {responsibilitiesData.map((responsibility, index) => (
              <View style={styles.list} key={index}>
                <View style={styles.bullet}>
                  <Text>•</Text>
                </View>
                <Text style={styles.text}>{responsibility}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={styles.h3}>
          {getString(`${LABEL_PREFIX}.afterHourServices.title`)}
        </Text>
        <Text style={styles.p}>
          {getString(`${LABEL_PREFIX}.afterHourServices.description`, {
            companyName: organizationName,
            phoneNumber: organizationContactPhone,
          })}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={styles.h3}>
          {getString(`${LABEL_PREFIX}.compliantProcedure.title`)}
        </Text>
        <Text style={styles.p}>
          {getString(`${LABEL_PREFIX}.compliantProcedure.description`, {
            companyName: organizationName,
          })}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  desc: {
    fontSize: 9,
    fontWeight: 400,
    marginTop: 12,
    color: '#323130',
  },
  rightsAndResponsibilitiesWrap: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  rightsAndResponsibilities: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingRight: 25,
  },
  h3: {
    fontSize: 9,
    fontWeight: 700,
    marginBottom: 5,
  },
  listWrap: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 4,
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
  },
  bullet: {
    marginLeft: 5,
    marginRight: 4,
    marginBottom: 0,
    height: '100%',
    marginTop: -8,
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    color: '#323130',
    marginBottom: 4,
  },
  sectionWrap: {
    marginTop: 10,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
  },
});

export default BillOfRightsAndResponsibilitiesPdfView;
