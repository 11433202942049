import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';

import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';

const LABEL_PREFIX = 'dme.deliveryPacketForm.generalHomeSafetyInformation';

const GeneralHomeSafetyInformationPdfView = ({ organizationDetail }) => {
  const { organizationName, organizationContactPhone } = organizationDetail;

  return (
    <View style={styles.container}>
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />

      <Text style={{ ...styles.text, marginTop: 15 }}>
        {getString(`${LABEL_PREFIX}.description`)}
      </Text>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.medicines.title`)}
        </Text>
        <View>
          {getString(`${LABEL_PREFIX}.medicines.informations`)?.map(
            (information, index) => (
              <Text key={index} style={styles.text}>
                • {information}
              </Text>
            ),
          )}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.mobilityItems.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.mobilityItems.description`)}
        </Text>
        <View>
          {getString(`${LABEL_PREFIX}.mobilityItems.informations`)?.map(
            (information, index) => (
              <Text key={index} style={styles.text}>
                • {information}
              </Text>
            ),
          )}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.slipsAndFalls.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.slipsAndFalls.description`)}
        </Text>
        <View>
          {getString(`${LABEL_PREFIX}.slipsAndFalls.informations`)?.map(
            (information, index) => (
              <Text key={index} style={styles.text}>
                • {information}
              </Text>
            ),
          )}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.electricalAccidents.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.electricalAccidents.description`)}
        </Text>
        <View>
          {getString(`${LABEL_PREFIX}.electricalAccidents.informations`)?.map(
            (information, index) => (
              <Text key={index} style={styles.text}>
                • {information}
              </Text>
            ),
          )}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.smellGas.title`)}
        </Text>
        <View>
          {getString(`${LABEL_PREFIX}.smellGas.informations`)?.map(
            (information, index) => (
              <View style={styles.row}>
                <Text key={index} style={styles.text}>
                  •.{' '}
                </Text>
                <Text style={styles.text}>{information}</Text>
              </View>
            ),
          )}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.fire.title`)}
        </Text>
        <View>
          {getString(`${LABEL_PREFIX}.fire.informations`)?.map(
            ({ content, subInformations }, index) => (
              <View key={index}>
                <View style={styles.row}>
                  <Text style={styles.text}>•. </Text>
                  <Text style={styles.text}>{content}</Text>
                </View>
                <View style={{ marginLeft: 10 }}>
                  {subInformations.map((information, index) => (
                    <View key={index}>
                      <Text style={styles.text}>• {information}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ),
          )}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.fireAction.title`)}
        </Text>
        <View style={styles.numberedList}>
          {getString(`${LABEL_PREFIX}.fireAction.informations`)?.map(
            ({ content, subInformations }, index) => (
              <View key={index}>
                <Text style={styles.text}>
                  {index + 1}. {content}
                </Text>
                <View style={{ marginLeft: 10 }}>
                  {subInformations?.map((information, index) => (
                    <View key={index} style={styles.sectionDeepList}>
                      <Text style={styles.text}>• {information}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ),
          )}
        </View>
      </View>

      <View
        style={{
          maxWidth: 300,
          alignSelf: 'center',
          marginTop: 30,
          marginBottom: 15,
        }}
      >
        <Text style={styles.headerThree}>
          {getString(`${LABEL_PREFIX}.registerAsHighPriorityCustomerForPrint`)}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.emergencyPreparednessPlan.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.emergencyPreparednessPlan.description`, {
            companyName: organizationName,
          })}
        </Text>
      </View>

      <View
        style={{
          maxWidth: 400,
          alignSelf: 'center',
          marginTop: 25,
          marginBottom: 12,
          textTransform: 'uppercase',
        }}
      >
        <Text style={styles.headerThree}>
          {getString(`${LABEL_PREFIX}.disasterPrevention.title`)}
        </Text>
      </View>
      {JSON.parse(
        getString(`${LABEL_PREFIX}.disasterPrevention.contents`, {
          phone: organizationContactPhone,
        }),
      ).map(({ title, informations }, index) => (
        <View key={index} style={styles.sectionWrap}>
          <Text style={styles.headerThree}>{title}</Text>
          <View>
            {informations.map((information, index) => (
              <Text key={index} style={styles.text}>
                • {information}
              </Text>
            ))}
          </View>
        </View>
      ))}

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.infectionControlInformation.title`)}
        </Text>
        <View style={styles.numberedList}>
          {getString(
            `${LABEL_PREFIX}.infectionControlInformation.informations`,
          )?.map((information, index) => (
            <Text key={index} style={styles.text}>
              {index + 1}. {information}
            </Text>
          ))}
        </View>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.abuseAndNeglect.title`)}
        </Text>
        {JSON.parse(
          getString(`${LABEL_PREFIX}.abuseAndNeglect.descriptions`, {
            companyName: organizationName,
          }),
        )?.map((description, index) => (
          <Text key={index} style={styles.text}>
            {description}
          </Text>
        ))}
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.advanceDirectives.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.advanceDirectives.description`)}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.definitionOfAdvanceDirectives.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(
            `${LABEL_PREFIX}.definitionOfAdvanceDirectives.description`,
          )}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.livingWill.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.livingWill.description`)}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.healthcareSurrogateDesignation.title`)}
        </Text>
        {getString(
          `${LABEL_PREFIX}.healthcareSurrogateDesignation.descriptions`,
        )?.map((description, index) => (
          <Text key={index} style={styles.text}>
            {description}
          </Text>
        ))}
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.advancedDirectiveUnderLaw.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.advancedDirectiveUnderLaw.description`)}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.changeMyMind.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(`${LABEL_PREFIX}.changeMyMind.description`)}
        </Text>
      </View>

      <View style={styles.sectionWrap}>
        <Text style={{ ...styles.headerThree, textDecoration: 'underline' }}>
          {getString(`${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.title`)}
        </Text>
        <Text style={styles.text}>
          {getString(
            `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.description`,
          )}
        </Text>

        <div>
          <Text style={styles.text}>
            {getString(
              `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.designatedHealthSurrogate.title`,
            )}
          </Text>
          <View>
            {getString(
              `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.designatedHealthSurrogate.actions`,
            )?.map((action, index) => (
              <Text key={index} style={styles.text}>
                • {action}
              </Text>
            ))}
          </View>
        </div>

        <Text style={{ ...styles.text, marginTop: 3 }}>
          {getString(
            `${LABEL_PREFIX}.shouldDoWithMyAdvanceDirective.changeYourAdvanceDirective`,
          )}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerThree: {
    fontSize: 9,
    fontWeight: 700,
    marginBottom: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    marginBottom: 2,
  },
  sectionWrap: {
    marginTop: 8,
  },
});

export default GeneralHomeSafetyInformationPdfView;
