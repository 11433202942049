export const COMPANY_NAME = 'Signetic Pharmacy';
export const COMAPNY_ADDRESS =
  '4517 Washington Ave. Manchester, Kentucky 39495';
export const COMPANY_PHONE = '(704) 555-0127';

export const EMERGENCY_PHONE = '100';

export const DBA = 'John Doe';

export const EFFECTIVE_DATE = '01/01/2019';

export const HOURS_OF_OPERATION = '09:00 - 18:00';

export const AUTHORIZATION_FORM_TITLE = 'Authorization Form';

export const RELATIONS_LIST = [
  { value: 'Father', label: 'Father' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Guardian', label: 'Guardian' },
];
